import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import ModalVideo from "react-modal-video";

function AboutUs() {
  const [toggler, setToggler] = useState(false);

  return (
    <div id="about-us-section">
      <div className="vr-section">
        <Container className="vr-items">
          <Row>
            <div className="vr-item">
              <div className="instruct-img">
                <img
                  src={require("../../assets/images/instruct-img.png")}
                  alt="instruct-img"
                  className="instruct-img"
                />
              </div>
              <h1 className="underlined-purple vr-title">
                MOBILE GAME EXPERIENCE
              </h1>
              <h2>
                Join the fight with flyers from around the world in the most
                authentic kite fighting game ever!
              </h2>
              <p>
                Kite Fighter Worldwide is an immersive PvP mobile game that will
                be launched on the Google Play store, and the IOS app store soon
                after. Join the global phenomena played by millions in
                developing countries around the world. Kite Fighting is simple
                in concept, but challenging in practice, where competitors
                maneuver their kites to cut their opponents line.
              </p>
              <p>
                The game features hundreds of custom kites with life-like flight
                physics, where players can challenge multiple real or NPC
                opponents in various destinations inspired by the places around
                the world where kite fighting thrives.
              </p>
              <button className="button" onClick={() => setToggler(true)}>
                WATCH THE TEASER
              </button>
              <ModalVideo
                channel="youtube"
                autoplay
                isOpen={toggler}
                videoId="gxnuEggxbLU"
                onClose={() => setToggler(false)}
              />
            </div>

            <div className="vr-item">
              <div className="instruct-img">
                <img
                  src={require("../../assets/images/NFT_Experience.png")}
                  alt="instruct-img"
                  className="instruct-img"
                />
              </div>
              <h1 className="underlined-blue vr-title">
                DIGITAL COLLECTIBLES EXPERIENCE
              </h1>
              <h2>
                Elevate the excitement with collectible kites and components!
              </h2>
              <p>
                The game experience is taken to the next level with the
                integration of limited-edition collectible kites. Each kite is
                programmatically generated from 100s of traits to create
                thousands of entirely unique playable in-game kites. But the
                utility of these collectibles goes way beyond the novelty of a
                1-of-1 game asset.
              </p>
              <p>
                The first collection, or Gen 0 Kites unlocks access to the Kite
                Club, an entirely new dimension of gameplay and
                social-connectivity, allowing players to earn kite components
                which can be used to build Gen 1 Kites. All collectible kites
                and components are sellable to other players via an in-game
                marketplace, allowing you to play and earn.
              </p>
              <button
                className="button"
                onClick={() => open("https://discord.gg/JZDUwby6xr", "_blank")}
              >
                JOIN DISCORD SERVER
              </button>
            </div>
          </Row>
        </Container>
      </div>

      <div className="utility-section">
        <Container>
          <div className="instruct-img">
            <img
              src={require("../../assets/images/NFT_Utility.png")}
              alt="NFT_Utility"
              className="NFT_Utility"
            />
          </div>
          <h1 className="underlined-orange utility-title">
            DIGITAL COLLECTIBLE UTILITY
          </h1>
          <div className="sub-head">
            <div className="utility-head-item">
              <span />
              <p>
                Early Beta access and front-of-line presale for the full Kite
                Fighter Worldwide game
              </p>
            </div>
            <div className="utility-head-item">
              <span />
              <p>Access to the Web3 features that unlocks the power to:</p>
            </div>

            <div className="utility-subhead-item">
              <span />
              <p>Earn Kite Component Rewards</p>
            </div>

            <div className="utility-subhead-item">
              <span />
              <p>Use Kite Components to build and mint new Gen1 Kites</p>
            </div>
            <div className="utility-subhead-item">
              <span />
              <p>
                Play-and-earn by buying, selling and trading Kites and
                Components on the in-app marketplac
              </p>
            </div>
            <div className="utility-subhead-item">
              <span />
              <p>
                View progress towards future rewards and limited time challenges
              </p>
            </div>
            <div className="utility-subhead-item">
              <span />
              <p>View the leaderboard for Kite Club players</p>
            </div>
            <div className="utility-head-item last">
              <span />
              <p>
                Plus exclusive access to our Discord server and community voting
                platforms where you can:
              </p>
            </div>
            <div className="utility-subhead-item">
              <span />
              <p>
                Network with Kite Fighting enthusiast, both virtual and IRL,
                around the world
              </p>
            </div>
            <div className="utility-subhead-item">
              <span />
              <p>Earn White List spots with partner projects</p>
            </div>
            <div className="utility-subhead-item">
              <span />
              <p>Win merch, gaming devices and NFT airdrops</p>
            </div>
            <div className="utility-subhead-item">
              <span />
              <p>Get beta access to play the game and provide feedback</p>
            </div>
            <div className="utility-subhead-item">
              <span />
              <p>Propose and vote on game improvements</p>
            </div>
            <div className="utility-subhead-item">
              <span />
              <p>
                Participate in our charitable actions to improve the quality of
                life in the communities where Kite Fighting was born
              </p>
            </div>
          </div>
        </Container>
      </div>
      <img
        src={require("../../assets/images/string_texture_vr_nft_experience.png")}
        alt="bottom string"
        className="bottom-string"
      />
    </div>
  );
}

export default AboutUs;
