import React from "react";
import Header from "../components/header/Header";
import AboutUs from "../components/about-us/About-us";
import Footer from "../components/footer/Footer";
import Roadmap from "../components/roadmap/Roadmap";
import Team from "../components/team/Team";
import Faq from "../components/faq/Faq";
import Navbar from "../components/navbar/Navbar";
import Burger from "../components/burger/Burger";


function Index() {
  return (
    <>
      <Navbar />
      <Burger />
      <Header />
      <AboutUs />
      <Roadmap />
      <Team />
      <Faq />
      <Footer />
    </>
  );
}

export default Index;
