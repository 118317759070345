import {Col} from "reactstrap";

const TeamMember = ({id, name, image, role1, role2, linkedIn, separatorColor}) => {
    return (
        <Col xs={12} md={6} lg={4}>
            <div className="team-member">
                <img src={image} alt={name} className="team-member-image"/>
                <div className="team-member-info">
                    <h3 className={`team-member-name ${separatorColor}`}>{name}</h3>
                    <h4 className={`team-member-role ${id}`}>{role1}</h4>
                    <h4 className="team-member-role">{role2}</h4>
                    <a href={linkedIn} target="_blank" rel="noopener noreferrer">
                        <img src={require('../../assets/images/linkedin.svg').default} alt="linkedin logo" className="linkedin-logo"/>
                    </a>
                </div>
            </div>
        </Col>
    )
}

export default TeamMember;