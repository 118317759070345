import { slide as Menu } from 'react-burger-menu'
import React, {useState} from "react";
import {Link} from "react-scroll";

function Burger() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Menu
            isOpen ={ isOpen }
            right
            elastic
            width={ 380 }
            onOpen ={ () => setIsOpen(true) }
            onClose ={ () => setIsOpen(false) }
        >
            <br/>
            <li className="underlined-blue" ><Link
                to="about-us-section"
                smooth={true}
                offset={450}
                duration={500}
                onClick={() => setIsOpen(false)}
            >MOBILE GAME</Link></li>
            <li className="underlined-blue"><Link
                to="about-us-section"
                smooth={true}
                offset={450}
                duration={500}
                onClick={() => setIsOpen(false)}
            >DIGITAL COLLECTIBLES</Link></li>
            <li className="underlined-blue"><Link
                to="roadmap-section"
                smooth={true}
                offset={450}
                duration={500}
                onClick={() => setIsOpen(false)}
            >ROAD MAP</Link></li>
            <li className="underlined-blue"><Link
                to="whitepaper-section"
                smooth={true}
                offset={150}
                duration={500}
                onClick={() => setIsOpen(false)}
            >WHITE PAPER</Link></li>
            <li className="underlined-blue"><Link
                to="team-section"
                smooth={true}
                offset={450}
                duration={500}
                onClick={() => setIsOpen(false)}
            >TEAM</Link></li>
            <li className="underlined-blue"><Link
                to="faq-section"
                smooth={true}
                offset={450}
                duration={500}
                onClick={() => setIsOpen(false)}
            >FAQ</Link></li>
        </Menu>
    );
}

export default Burger