import React from 'react';
import FitText from "react-fittext";

function Header() {

    return (
        <div id="header-section">
            <div className="content-box">
                <img src={require('../../assets/images/kite_fighter_logo.png')} alt="logo" className="content-box-image"/>
                <FitText compressor={1.17}>
                    <h3 className="content-box-title top">A BREAKTHROUGH MOBILE GAMING &</h3>
                </FitText>
                <FitText compressor={1.14}>
                    <h3 className="content-box-title bottom underlined-blue">DIGITAL COLLECTIBLE EXPERIENCE</h3>
                </FitText>
                <h4 className="content-box-description">Featuring one of the most popular sports you’ve never heard of.</h4>
                <div className="social-icons">
                    <img src={require('../../assets/images/discord.svg').default} alt="discord" className="social-icon"     onClick={() => open("https://discord.gg/JZDUwby6xr", "_blank")}/>
                    <img src={require('../../assets/images/twitter.svg').default} alt="twitter" className="social-icon"     onClick={() => open("https://twitter.com/KiteFightergg", "_blank")}/>
                    <img src={require('../../assets/images/instagram.svg').default} alt="instagram" className="social-icon" onClick={() => open("https://www.instagram.com/kitefightergg/", "_blank")}/>
                    <img src={require('../../assets/images/tiktok.svg').default} alt="tiktok" className="social-icon"       onClick={() => open("https://www.tiktok.com/@kitefightergg", "_blank")}/>
                    <img src={require('../../assets/images/linkedin.svg').default} alt="linkedin" className="social-icon"   onClick={() => open("https://www.linkedin.com/company/kite-fighters-worldwide/", "_blank")}/>
                    <img src={require('../../assets/images/telegram.svg').default} alt="telegram" className="social-icon"   onClick={() => open("https://t.me/+IEkdhBQmSHs3OGZh", "_blank")}/>
                    <img src={require('../../assets/images/mirror.svg').default} alt="mirror" className="social-icon"   onClick={() => open("https://mirror.xyz/kitefighter.eth", "_blank")}/>
                    <img src={require('../../assets/images/youtube.svg').default} alt="youtube" className="social-icon"   onClick={() => open("https://www.youtube.com/channel/UCpOGXou4zLJNVV3xIcVc-hg", "_blank")}/>
                    <img src={require('../../assets/images/facebook.svg').default} alt="facebook" className="social-icon"   onClick={() => open("https://www.facebook.com/KiteFightergg", "_blank")}/>
                </div>
                <button className="button" onClick={() => open("https://discord.gg/JZDUwby6xr", "_blank")}>JOIN DISCORD SERVER</button>
            </div>
            <img src={require('../../assets/images/hawk-man.png')} alt="vr player" className="vr-player"/>
            <img src={require('../../assets/images/kite_shape_hero.png')} alt="kite bottom" className="bottom-square"/>
            <img src={require('../../assets/images/kite_texture_hero.png')} alt="kite texture" className="kite-texture"/>
        </div>
    );
}

export default Header
