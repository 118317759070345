import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";

function Navbar() {
  return (
    <div id="navbar">
      <div className="navbar-pc">
        <img
          src={require("../../assets/images/nav_logo.png")}
          alt="logo"
          className="logo"
        />
        <ul id="navbar-pc-ul">
          <li className="navbar-pc-ul-element">
            <Link
              to="about-us-section"
              smooth={true}
              offset={450}
              duration={500}
            >
              MOBILE GAME
            </Link>
          </li>
          <li className="navbar-pc-ul-element">
            <Link
              to="about-us-section"
              smooth={true}
              offset={450}
              duration={500}
            >
              DIGITAL COLLECTIBLES
            </Link>
          </li>
          <li className="navbar-pc-ul-element">
            <Link
              to="roadmap-section"
              smooth={true}
              offset={450}
              duration={500}
            >
              ROAD MAP
            </Link>
          </li>
          <li className="navbar-pc-ul-element">
            <Link
              to="whitepaper-section"
              smooth={true}
              offset={150}
              duration={500}
            >
              WHITE PAPER
            </Link>
          </li>
          <li className="navbar-pc-ul-element">
            <Link to="team-section" smooth={true} offset={450} duration={500}>
              TEAM
            </Link>
          </li>
          <li className="navbar-pc-ul-element">
            <Link to="faq-section" smooth={true} offset={450} duration={500}>
              FAQ
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
