import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { NavLink } from "react-router-dom";
function DiscordNavbar() {
  return (
    <div id="mintNavbar">
      <div className="mintNavbar">
        <img
          src={require("../../assets/images/nav_logo.png")}
          alt="logo"
          className="mintLogo"
        />
        <ul id="mintNavbar-ul">
          <li className="mintNavbar-ul-element">
            <NavLink to="/" smooth={true} offset={450} duration={500}>
              GO TO SITE
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default DiscordNavbar;