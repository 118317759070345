import React from "react";
import {Col, Container, Row} from "reactstrap";
import {Link} from "react-scroll";
import {useNavigate} from "react-router-dom";

function Footer() {
    const navigate = useNavigate();
    return (
        <>
            <div id="footer-section">
                <img src={require('../../assets/images/footer_kite_texture.png')} alt="kite texture" className="top-texture"/>
                <Container>
                    <Row className="justify-content-center">
                        <Col md="4" className="justify-content-center justify-content-md-start">
                            <img src={require('../../assets/images/kite_fighter_logo.png')} alt="footer logo" className="footer-logo"/>
                            <p className="copyright">©2022, All Rights Reserved.</p>
                            <div className="social-icons">
                                <img src={require('../../assets/images/discord.svg').default} alt="discord" className="social-icon"     onClick={() => open("https://discord.gg/JZDUwby6xr", "_blank")}/>
                                <img src={require('../../assets/images/twitter.svg').default} alt="twitter" className="social-icon"     onClick={() => open("https://twitter.com/KiteFightergg", "_blank")}/>
                                <img src={require('../../assets/images/instagram.svg').default} alt="instagram" className="social-icon" onClick={() => open("https://www.instagram.com/kitefightergg/", "_blank")}/>
                                <img src={require('../../assets/images/tiktok.svg').default} alt="tiktok" className="social-icon"       onClick={() => open("https://www.tiktok.com/@kitefightergg", "_blank")}/>
                                <img src={require('../../assets/images/linkedin.svg').default} alt="linkedin" className="social-icon"   onClick={() => open("https://www.linkedin.com/company/kite-fighters-worldwide/", "_blank")}/>
                                <img src={require('../../assets/images/telegram.svg').default} alt="telegram" className="social-icon"   onClick={() => open("https://t.me/+IEkdhBQmSHs3OGZh", "_blank")}/>
                                <img src={require('../../assets/images/mirror.svg').default} alt="mirror" className="social-icon"   onClick={() => open("https://mirror.xyz/kitefighter.eth", "_blank")}/>
                                <img src={require('../../assets/images/youtube.svg').default} alt="youtube" className="social-icon"   onClick={() => open("https://www.youtube.com/channel/UCpOGXou4zLJNVV3xIcVc-hg", "_blank")}/>
                                <img src={require('../../assets/images/facebook.svg').default} alt="facebook" className="social-icon"   onClick={() => open("https://www.facebook.com/KiteFightergg", "_blank")}/>
                            </div>
                        </Col>
                        <Col md="4" className="text-center">
                            <div className="footer-links">
                                <h5 className="footer-title">EXPLORE</h5>
                                <ul className="footer-ul">
                                    <li className="footer-element"><Link
                                        to="about-us-section"
                                        smooth={true}
                                        offset={450}
                                        duration={500}
                                    >MOBILE GAME</Link></li>
                                    <li className="footer-element"><Link
                                        to="about-us-section"
                                        smooth={true}
                                        offset={450}
                                        duration={500}
                                    >DIGITAL COLLECTIBLES</Link></li>
                                    <li className="footer-element"><Link
                                        to="roadmap-section"
                                        smooth={true}
                                        offset={450}
                                        duration={500}
                                    >ROAD MAP</Link></li>
                                    <li className="footer-element"><Link
                                        to="whitepaper-section"
                                        smooth={true}
                                        offset={150}
                                        duration={500}
                                    >WHITE PAPER</Link></li>
                                    <li className="footer-element"><Link
                                        to="team-section"
                                        smooth={true}
                                        offset={450}
                                        duration={500}
                                    >TEAM</Link></li>
                                    <li className="footer-element"><Link
                                        to="faq-section"
                                        smooth={true}
                                        offset={450}
                                        duration={500}
                                    >FAQ</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md="4" className="text-center">
                            <div className="footer-links">
                                <h5 className="footer-title">RESOURCES</h5>
                                <p className="footer-element" onClick={() => window.location = 'mailto:info@kitefighter.gg'}>CONTACT US</p>
                                <p className="footer-element" onClick={() => navigate('/privacy-policy')}>PRIVACY POLICY</p>
                                <p className="footer-element d-none">TERMS OF SERVICE</p>
                                <p className="footer-element" onClick={() => open("https://mirror.xyz/0x9382659482027140056f9E7A46CFDa3973E4B2C2/subscribe")}>BLOG</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Footer