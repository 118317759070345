import React from "react";
import { Container } from "reactstrap";
import DiscordNavbar from "../components/discordnavbar/discordNavbar";

function Discord() {
  return (
    <div>
      <DiscordNavbar />
      <div className="discordPage">
        <div className="disField">
            <Container fluid="xl">
            <div className="discordContainer">
              <div className="discordField">
                <div className="discordHeading">
                  <h1>
                    Join the global community of real-world and virtual kite
                    fighters on Discord.
                  </h1>
                  <span className="underlined-blue-discord"></span>
                </div>
                <div className="discordTexts">
                  <h2>
                    Kite fighting is played worldwide. So there should be an
                    online community for every person in every region to come
                    together and share their passion for the sport.
                  </h2>
                  <h3>
                    Now there is, and you can become a member for free on the
                    Kite Fighter Worldwide Discord Server.
                  </h3>
                </div>
                <button className="button" onClick={() => open("https://discord.gg/JZDUwby6xr", "_blank")}>JOIN DISCORD SERVER</button>
              </div>
            </div>
          </Container>
          <div className="discordFooter">
            <img
              src={require("../assets/images/discordFooter.png")}
              alt="footer"
              className="discordFooter"
            />
          </div>
          <div className="kiteLogo">
            <img
              src={require("../assets/images/Kite_Logo.png")}
              alt="logo"
              className="kiteLogo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Discord;
