import React from "react";

function Roadmap() {
  return (
    <div id="roadmap-section">
      <h1 className="underlined-pink title">ROAD MAP</h1>
      <img
        src={require("../../assets/images/roadmap.png")}
        alt="roadmap"
        className="roadmap-image d-none d-md-block"
      />
      <img
        src={require("../../assets/images/roadmap_mobile.png")}
        alt="roadmap"
        className="roadmap-image roadmap-mobile d-block d-md-none"
      />
      <h1
        id="whitepaper-section"
        className="underlined-purple title"
        style={{ paddingTop: "400px" }}
      >
        WHITE PAPER
      </h1>
      <div className="white-paper-container">
        <h5>
          Learn more about the Kite Fighter Digital Kite Collection, and its
          utility inside and out of the Kite Fighter Worldwide game.
        </h5>
        <p>
          STATUS: <span className="blue">In Development</span>
        </p>
        <button
          className="button"
          onClick={() => open("https://discord.gg/JZDUwby6xr", "_blank")}
        >
          JOIN DISCORD SERVER
        </button>
      </div>
      <img
        src={require("../../assets/images/string_texture_white_paper.png")}
        alt="bottom string"
        className="bottom-string"
      />
    </div>
  );
}

export default Roadmap;
