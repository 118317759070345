import React, {useEffect} from 'react';
import {Container} from "reactstrap";

function Faq() {
    const questions = [
        {
            question: 'When will the VR game be released?',
            answer: "The official release date is TBD, and will be determined by how quickly we sell out the Gen0 Kite NFTs."
        },
        {
            question: 'When can I mint the NFTs?',
            answer: "We will announce the mint date once our discord community grows in membership and activity, proving we have the enthusiastic audience needed to successfully launch the mint. Target date is late 2022."
        },
        {
            question: 'How do I mint or purchase the NFTs?',
            answer: "Upon launch, a MINT NOW button will appear on our website homepage. Users need only connect their wallet (such as MetaMask) and use the required currency(s) to purchase. We will announce which currencies we support prior to mint. We are also working on a fiat purchasing option.\n" +
                "\n" +
                "Once the mint opens the NFTs will also be available for purchase from minters on select secondary markets such as OpenSea and Venly."
        },
        {
            question: 'How many Gen0 Kite NFTs will there be and how much will they cost?',
            answer: "This info is TBA."
        },
        {
            question: 'Is there a whitelist? If so, how do I get in on it?',
            answer: "There sure is. We will announce all the WL participation rules, challenges and tasks soon. For now, we suggest just being an active member here on Discord 😉 "
        },
        {
            question: 'Can I buy new or custom kite designs?',
            answer: "Kites will come in NFT and non-NFT form. NFT based kites will be released in the Gen0 Kite sale, and custom Gen1 Kite NFTs can be created using in-game rewards that come in the form of NFT kite components.\n" +
                "\n" +
                "Non-NFT kites can be purchased in-game, but are not resalable or tradeable within the Web3 companion app. "
        },
        {
            question: 'How do I unlock my NFT rewards?',
            answer: "NFT holders may access all of their rewards by connecting their wallet to the Web3 companion app, and syncing their profile with their Steam account.\n" +
                "\n" +
                "All other rewards will be accessed through this Discord server by verifying NFT ownership."
        },
        {
            question: 'Is the mobile game available in my country?',
            answer: "The game will be accessible in any region that supports the Google Play Store and Apple App Store game marketplace."
        },
        {
            question: 'Are the NFTs and Web3 features available in my country?',
            answer: "The legality of crypto-assets is different from country to country, with very few that outlaw them completely. Kite Fighter Worldwide will make no efforts to block any users from participating. Check your local laws."
        },
    ]

    useEffect(() => {
        let question = document.querySelectorAll(".question-container");

        question.forEach(question => {
            question.addEventListener("click", () => {
                const active = document.querySelector(".question-container.active");
                if(active && active !== question ) {
                    active.classList.toggle("active");
                    active.getElementsByTagName("div")[1].style.maxHeight = 0;
                }
                question.classList.toggle("active");
                const answer = question.getElementsByTagName("div")[1];
                if(question.classList.contains("active")){
                    answer.style.maxHeight = "400px";
                } else {
                    answer.style.maxHeight = 0;
                }
            })
        })
    }, [])

    const renderQuestions = () => {
        return questions.map((q, i) => {
            return <div key={i}>
                <div className="container question-container">
                    <div className="question">
                        {q.question}
                    </div>
                    <div className="answercont">
                        <div className="answer">
                            {q.answer}
                            {q.link && <><br/><br/> <a href={q.link.url}>{q.link.text}</a></>}
                        </div>
                    </div>
                </div>
            </div>
        })
    }

    return (
        <div id="faq-section">
            <h1 className="underlined-orange title" style={{fontSize: "50px"}}>FAQs</h1>
            <Container style={{marginTop: "150px"}}>
                {renderQuestions()}
                <h3>Have more questions?</h3>
                <button className="button" onClick={() => open("https://discord.gg/JZDUwby6xr", "_blank")}>JOIN DISCORD SERVER</button>
            </Container>
            <img src={require('../../assets/images/string_texture_faq.png')} alt="string texture" className="string-texture"/>
        </div>
    );
}

export default Faq;
