import React from "react";
import TeamMember from "./TeamMember";
import { Row } from "reactstrap";

function Team() {
  return (
    <div id="team-section">
      <h1 className="underlined-dark-purple title">TEAM</h1>
      <div className="team-container container">
        <Row className="justify-content-center">
          <TeamMember
            name="BILL BINGHAM"
            image={require("../../assets/images/Bill.png")}
            role1="CEO/Founder of Griffins Digital"
            role2="Game Publisher"
            linkedIn="https://www.linkedin.com/in/bill-bingham-46118b21b/"
            separatorColor="orange"
            id="bill-bingham"
          />
          <TeamMember
            name="DAN KELLY"
            image={require("../../assets/images/Dan.png")}
            role1="CEO/Founder of The Factory"
            role2="Game Development Studio"
            linkedIn="https://www.linkedin.com/in/wootystyle"
            separatorColor="blue"
            id="dan-kelly"
          />
          <TeamMember
            name="ALEXANDER MEYER"
            image={require("../../assets/images/Alex.png")}
            role1="CEO/Founder of D¢ENT Ventures"
            role2="Web3 Product Development Studio"
            linkedIn="https://www.linkedin.com/in/alexandermeyer48/"
            separatorColor="purple"
            id="alex-meyer"
          />
        </Row>
      </div>
      <img
        src={require("../../assets/images/team_kite_texture.png")}
        alt="kite texture"
        className="kite-texture"
      />
    </div>
  );
}

export default Team;
