import React from "react";
import ReactDOM from "react-dom";
import "./assets/scss/main.scss";
import "react-modal-video/scss/modal-video.scss";
import Index from "./views/Index";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Mint from "./views/Mint";
import Discord from "./views/Discord";
import PrivacyPolicy from "./views/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
        <ScrollToTop />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="mint" element={<Mint />} />
        <Route path="discord" element={<Discord />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
