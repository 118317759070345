import React, { useState } from "react";
import { Container } from "reactstrap";
import MintNavbar from "../components/mintnavbar/mintNavbar";
import { Venly } from "@venly/web3-provider";
import { ethers } from "ethers";
import { WinterCheckout } from "@usewinter/checkout";

function Mint() {
  const [, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [address, setAddress] = useState(null);

  const [showWinter, setShowWinter] = useState(false);

  window.addEventListener("message", (event) => {
    console.log(`Received message: ${event.data}`);

    if (event.data === "closeWinterCheckoutModal") {
      setShowWinter(false);
    }
  });

  React.useEffect(() => {
    const checkConnection = async () => {
      // Check if browser is running Metamask
      let web3;
      if (window.ethereum) {
        web3 = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(web3);
        setSigner(web3.getSigner());
      }

      // Check if User is already connected by retrieving the accounts
      const accounts = await web3.listAccounts();
      if (accounts.length > 0) {
        setAddress(accounts[0]);
      }
    };
    checkConnection();
  }, []);

  const connectWallet = async (type) => {
    if (type === "venly") {
      const provider = await Venly.createProviderEngine({
        clientId: "Testaccount",
        signMethod: "POPUP",
        secretType: "MATIC",
        environment: "staging",
        skipAuthentication: false,
      });
      console.log(provider);
      const web3Provider = new ethers.providers.Web3Provider(provider);
      const signer = web3Provider.getSigner();
      setProvider(web3Provider);
      setSigner(signer);
      setAddress(await signer.getAddress());
    } else {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(web3Provider);
        setSigner(web3Provider.getSigner());
        setAddress(await signer.getAddress());
      } catch (e) {
        console.log("User denied account access...");
      }
    }
  };

  const disconnectWallet = () => {
    setProvider(null);
    setSigner(null);
    setAddress(null);
  };

  return (
    <div>
      <WinterCheckout
        projectId={7090}
        walletAddress={address}
        showModal={showWinter}
        production={false}
        testnet="mumbai"
      />
      <MintNavbar />
      <div className="mintPage">
        <div className="backOverlay">
          <Container>
            <div className="alignCenter">
              <div className="mintBox">
                <div className="mintHeading">
                  <div className="h1Text">
                    <h1>Mint Your Gen0 NFTs</h1>
                    <span className="underlined-blue-discord"></span>
                  </div>
                </div>
                <div className="mint-name">
                  <div>
                    <h2>MINT Price</h2>
                  </div>
                  <div className="mint-price">
                    <p>0.3 ETH / 500 USD</p>
                  </div>
                </div>
                <div className="currentlyMinted">
                  <h3 className="mintTitle">Currently Minted Supply</h3>
                  <h4 className="mintTitleCount">300/500</h4>
                </div>
                <div className="mintName ">
                  <div
                    className="mintPrice"
                    style={{
                      background: "rgba(0,0,0,0.56)",
                      width: "100%",
                      justifyContent: "start",
                      margin: "5px",
                    }}
                  >
                    <div
                      className="mintPrice black"
                      style={{
                        height: "20px",
                        background: "#ca16cb",
                        width: "70%",
                      }}
                    >
                      <p></p>
                    </div>
                  </div>
                </div>
                <p className="mintTitle">Mint Amount</p>
                <div className="mintCounter">
                  <div className="mintName mintCount">
                    <button>–</button>
                    <div
                      className="mintPrice"
                      style={{
                        background: "rgba(0,0,0,0.26)",
                        border: "2px solid #8e3fff",
                        width: "100%",
                        justifyContent: "start",
                        margin: "5px",
                      }}
                    >
                      <div className="mintPrice">
                        <p>1</p>
                      </div>
                    </div>
                    <button>+</button>
                  </div>
                </div>
                <p className="mintTitle walletTitle">Wallet Address</p>
                <div className="mintWallet ">
                  <div className="walletAddress">
                    <div className="walletBtn">
                      {address ? (
                        <div className="connected-wallet">
                          <p style={{ color: "black" }}>{address}</p>
                          <button onClick={disconnectWallet}>Switch</button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="priceTitle">
                  <h1>TOTAL PRICE</h1>
                  <h4>0.052 ETH / 800 USD</h4>
                </div>
                <div className="mintBtn">
                  <div className="button1">
                    <button onClick={() => connectWallet("metamask")}>
                      Connect Wallet
                    </button>
                  </div>
                  <div className="button2">
                    <button onClick={() => connectWallet("venly")}>
                      Connect with Venly
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <div className="kiteLogo">
            <img
              src={require("../assets/images/Kite_Logo.png")}
              alt="logo"
              className="kiteLogo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mint;
